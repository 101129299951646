// src/components/AuthLayout.js

import React from 'react';

import '../assets/plugins/global/plugins.bundle.css';
import '../assets/css/style.bundle.css';

import bg4 from '../assets/media/auth/bg4.jpg';
import bg4dark from '../assets/media/auth/bg4-dark.jpg';

const AuthLayout = ({ children }) => {
    return (
        <div className="d-flex flex-column flex-root" id="kt_app_root">
            {/* Page Background Image */}
            <style>
                {`
          body { background-image: url(${bg4}); }
          [data-bs-theme="dark"] body { background-image: url(${bg4dark}); }
        `}
            </style>
            {/* Authentication Layout */}
            <div className="d-flex flex-column flex-column-fluid flex-lg-row" style={{justifyContent:'center'}}>
                {/* Aside Section */}

                {/* Main Content Section */}
                <div className="d-flex flex-center p-10">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default AuthLayout;
