// Users.js
import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import AdminLayout from '../layouts/AdminLayout';
import { db } from '../firebase'; // Adjust the path as needed
import { collection, getDocs } from 'firebase/firestore';
import { Link } from 'react-router-dom';

const Users = () => {
    const [users, setUsers] = useState([]); // State to store users data

    useEffect(() => {
        // Fetch users data from Firestore
        const fetchUsers = async () => {
            try {
                const usersCollection = collection(db, 'users');
                const usersSnapshot = await getDocs(usersCollection);
                const usersList = usersSnapshot.docs.map(doc => ({
                    id: doc.id, // Add the document ID
                    ...doc.data() // Spread the document data
                }));
                setUsers(usersList); // Set users data
            } catch (error) {
                console.error("Error fetching users data:", error);
            }
        };

        fetchUsers();
    }, []);

    // Define columns for the data table
    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            width: '250px',

        },
        {
            name: 'Gender',
            selector: row => row.gender,
            sortable: true
        },
        {
            name: 'Day',
            selector: row => row.day,
            sortable: true
        },
        {
            name: 'Day Status',
            selector: row => row.dayStatus,
            sortable: true
        },
        {
            name: 'Subscription Type',
            selector: row => row.subscriptionType,
            sortable: true,
            width: '250px',

        },
        {
            name: 'Is Active',
            selector: row => row.isActive ? 'Yes' : 'No',
            sortable: true
        },
        {
            name: 'Last Login Date',
            selector: row => row.lastLoginDate ? new Date(row.lastLoginDate.seconds * 1000).toLocaleString() : 'N/A',
            sortable: true,
            width: '250px',

        },
        {
            name: 'Last Day Completed Date',
            selector: row => row.lastDayCompletedDate ? new Date(row.lastDayCompletedDate.seconds * 1000).toLocaleString() : 'N/A',
            sortable: true,
            width: '250px',

        },
        {
            name: 'Subscription End Date',
            selector: row => row.subscriptionEndDate ? new Date(row.subscriptionEndDate.seconds * 1000).toLocaleString() : 'N/A',
            sortable: true,
            width: '250px',
        }
    ];

    // Custom styling for the data table
    const customStyles = {
        headRow: {
            style: {
                borderTop: '1px solid #e3e3e3', // Top border for header row
                borderBottom: '2px solid #e3e3e3', // Bottom border for header row
            },
        },
        headCells: {
            style: {
                fontWeight: 'bold',
                fontSize: '16px',
                backgroundColor: '#f5f5f5',
                borderRight: '1px solid #e3e3e3', // Right border for header cells
                borderLeft: '1px solid #e3e3e3', // Left border for header cells (optional)
                borderBottom: '1px solid #e3e3e3', // Bottom border for header cells
                padding: '8px', // Add some padding for better appearance
            },
        },
        cells: {
            style: {
                whiteSpace: 'unset', // Allow text wrapping in cells
                overflow: 'hidden',
                textOverflow: 'ellipsis', // Add ellipsis for overflow text in cells
                fontSize: '14px',
                borderRight: '1px solid #e3e3e3', // Right border for cells
                borderLeft: '1px solid #e3e3e3', // Left border for cells (optional)
                borderBottom: '1px solid #e3e3e3', // Bottom border for cells
                padding: '8px', // Add some padding for better appearance
            },
        },
        rows: {
            style: {
                minHeight: '40px', // Override the row height
                borderBottom: '1px solid #e3e3e3', // Border between rows
            }
        },
        pagination: {
            style: {
                borderTop: '1px solid #e3e3e3', // Top border for pagination
            }
        }
    };



    return (
        <AdminLayout>
            <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                <div id="kt_app_toolbar_container" className="app-container container-fluid d-flex flex-stack">
                    <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Metamindful</h1>
                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                            <li className="breadcrumb-item text-muted">
                                <Link to="/home" className="text-muted text-hover-primary">Home</Link>

                            </li>
                            <li className="breadcrumb-item">
                                <span className="bullet bg-gray-400 w-5px h-2px"></span>
                            </li>
                            <li className="breadcrumb-item text-muted">Users List</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div id="kt_app_content" className="app-content flex-column-fluid">
                <div id="kt_app_content_container" className="app-container container-fluid">
          <div className="card card-body">
                    <DataTable
                        columns={columns}
                        data={users}
                        pagination
                        highlightOnHover
                        striped
                        responsive
                        customStyles={customStyles}
                        subHeader
                        subHeaderComponent={
                            <input
                                type="text"
                                placeholder="Search Users"
                                className="w-25 form-control"
                                onChange={(e) => {
                                    const searchText = e.target.value.toLowerCase();
                                    setUsers(users.filter(user =>
                                        user.name?.toLowerCase().includes(searchText) ||
                                        user.email?.toLowerCase().includes(searchText) ||
                                        user.gender?.toLowerCase().includes(searchText) ||
                                        user.day?.toString().includes(searchText) ||
                                        user.dayStatus?.toLowerCase().includes(searchText) ||
                                        user.subscriptionType?.toLowerCase().includes(searchText)
                                    ));
                                }}
                            />
                        }
                        style={{ width: '100%' }} // Set the DataTable width to 100%

                    />
          </div>
                </div>
            </div>
        </AdminLayout>
    );
};

export default Users;
