// useDashboardStats.js
import { useState, useEffect } from 'react';
import { db } from '../firebase'; // Adjust the path as needed
import { collection, getDocs } from 'firebase/firestore'; // Import necessary Firestore functions

const useDashboardStats = () => {
    const [stats, setStats] = useState({
        totalUsers: 0,
        activeUsers: 0,
        inactiveUsers: 0,
        paidUsers: 0,
        trialUsers: 0,
        expiredSubscriptions: 0,
        activeSubscriptions: 0,
        genderDistribution: { male: 0, female: 0 },
        dayStatusCounts: {}, // Users grouped by day and status
        usersPerDayStatus: {}, // Users grouped by day and status (pending and not pending)
        topUsersByLastDayCompleted: [], // Top 10 users by lastDayCompletedDate
        topRecentLogins: [],
        topOldestLogins: [],
        totalChatSessions: 0,
        upcomingRenewals: 0
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Access the 'users' collection
                const usersCollection = collection(db, 'users');
                const usersSnapshot = await getDocs(usersCollection);
                const users = usersSnapshot.docs.map(doc => doc.data());

                const totalUsers = users.length;
                const activeUsers = users.filter(user => user.isActive).length;
                const inactiveUsers = users.filter(user => !user.isActive).length;
                const paidUsers = users.filter(user => user.isPaid).length;
                const trialUsers = users.filter(user => user.isTrial).length;
                const expiredSubscriptions = users.filter(user => user.isExpired).length;
                const activeSubscriptions = users.filter(user => {
                    return user.subscriptionEndDate && user.subscriptionEndDate.toDate() > new Date();
                }).length;

                const genderDistribution = users.reduce((acc, user) => {
                    if (user.gender) {
                        const gender = user.gender.toLowerCase(); // Normalize the gender string
                        acc[gender] = (acc[gender] || 0) + 1; // Increment the count for the gender
                    }
                    return acc;
                }, { male: 0, female: 0 });

                // Calculate users per day with status
                const usersPerDayStatus = users.reduce((acc, user) => {
                    if (user.day !== undefined) {
                        const day = `Day ${user.day}`; // Label the day (e.g., day1, day2)
                        const status = user.dayStatus === 'pending' ? 'pending' : 'not_pending';

                        if (!acc[day]) {
                            acc[day] = { pending: 0, not_pending: 0 };
                        }
                        acc[day][status] += 1;
                    }
                    return acc;
                }, {});

                const topRecentLogins = users
                    .filter(user => user.email && user.lastLoginDate) // Ensure email and valid lastLoginDate
                    .sort((a, b) => b.lastLoginDate.toDate() - a.lastLoginDate.toDate()) // Convert to Date for comparison
                    .slice(0, 10); // Get the top 10

                const topOldestLogins = users
                    .filter(user => user.email && user.lastLoginDate) // Ensure email and valid lastLoginDate
                    .sort((a, b) => a.lastLoginDate.toDate() - b.lastLoginDate.toDate()) // Convert to Date for comparison
                    .slice(0, 10); // Get the top 10 oldest logins



                const totalChatSessions = users.reduce((acc, user) => acc + user.chatCounts, 0);

                const upcomingRenewals = users.filter(user => {
                    if (!user.subscriptionEndDate) return false;
                    const endDate = user.subscriptionEndDate.toDate();
                    const today = new Date();
                    return (endDate - today) / (1000 * 60 * 60 * 24) <= 7;
                }).length;

                // Top 10 users ordered by lastDayCompletedDate
                const topUsersByLastDayCompleted = users
                    .filter(user => user.lastDayCompletedDate && user.day) // Ensure lastDayCompletedDate and day are present
                    .sort((a, b) => b.lastDayCompletedDate.toDate() - a.lastDayCompletedDate.toDate()) // Sort by lastDayCompletedDate
                    .slice(0, 10) // Get top 10 users
                    .map(user => ({ // Map to include day number and relevant info
                        email: user.email,
                        day: user.day,
                        lastDayCompletedDate: user.lastDayCompletedDate.toDate().toLocaleString()
                    }));

                setStats({
                    totalUsers,
                    activeUsers,
                    inactiveUsers,
                    paidUsers,
                    trialUsers,
                    expiredSubscriptions,
                    activeSubscriptions,
                    genderDistribution,
                    dayStatusCounts: usersPerDayStatus, // Updated to include users per day with status
                    topUsersByLastDayCompleted, // Top 10 users by lastDayCompletedDate with day number
                    topRecentLogins,
                    topOldestLogins,
                    totalChatSessions,
                    upcomingRenewals
                });
            } catch (error) {
                console.error("Error fetching data from Firestore:", error);
            }
        };

        fetchData();
    }, []);

    return stats;
};

export default useDashboardStats;
