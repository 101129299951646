import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AuthLayout from '../../layouts/AuthLayout';
import logo from "../../assets/logo.png";

// Hardcoded credentials
const validCredentials = {
    email: 'admin@gmail.com',
    password: '123456',
};

const Login = () => {
    const navigate = useNavigate();

    const validationSchema = Yup.object({
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        password: Yup.string()
            .min(6, 'Password must be at least 6 characters')
            .required('Password is required'),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema,
        onSubmit: (values, { setSubmitting, setErrors }) => {
            if (
                values.email === validCredentials.email &&
                values.password === validCredentials.password
            ) {
                localStorage.setItem('isAuthenticated', 'true');
                navigate('/home'); // Redirect to home page after successful login
            } else {
                setErrors({ password: 'Invalid email or password' });
            }
            setSubmitting(false);
        },
    });

    return (
        <AuthLayout>
            <div className="card rounded-3 w-md-550px">
                <div className="card-body d-flex flex-column p-10 p-lg-20 pb-lg-10">
                    <div className="d-flex flex-center flex-column-fluid pb-15 pb-lg-20">
                        <form className="form w-100" id="kt_sign_in_form" onSubmit={formik.handleSubmit}>
                            <div className="text-center mb-11">
                                <img alt="Logo" src={logo} style={{ maxWidth: '100px' }} />
                                <h1 className="text-dark fw-bolder mb-3">Sign In</h1>
                            </div>

                            <div className="fv-row mb-8">
                                <input
                                    type="text"
                                    placeholder="Email"
                                    name="email"
                                    className={`form-control bg-transparent ${formik.touched.email && formik.errors.email ? 'is-invalid' : ''}`}
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.email && formik.errors.email ? (
                                    <div className="text-danger">{formik.errors.email}</div>
                                ) : null}
                            </div>

                            <div className="fv-row mb-3">
                                <input
                                    type="password"
                                    placeholder="Password"
                                    name="password"
                                    className={`form-control bg-transparent ${formik.touched.password && formik.errors.password ? 'is-invalid' : ''}`}
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.password && formik.errors.password ? (
                                    <div className="text-danger">{formik.errors.password}</div>
                                ) : null}
                            </div>

                            <div className="d-grid mb-10">
                                <button
                                    type="submit"
                                    id="kt_sign_in_submit"
                                    className="btn btn-primary"
                                    disabled={formik.isSubmitting}
                                >
                                    <span className="indicator-label">Sign In</span>
                                    {formik.isSubmitting && (
                                        <span className="indicator-progress">
                                            Please wait...
                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                    )}
                                </button>
                            </div>

                            {formik.errors.password && (
                                <div className="text-danger text-center">{formik.errors.password}</div>
                            )}
                        </form>
                    </div>
                </div>
            </div>
        </AuthLayout>
    );
};

export default Login;
