import React from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import logo from '../assets/metamindful.png'; // Import your logo image
import minlogo from '../assets/logo.png'; // Import your logo image

const Sidebar = () => {
    const location = useLocation(); // Get the current location
    const navigate = useNavigate(); // Hook for programmatic navigation

    // Function to determine if a menu item is active
    const isActive = (pathname) => {
        return location.pathname === pathname ? 'active' : '';
    };

    // Logout function
    const handleLogout = () => {
        // Clear the authentication flag from localStorage
        localStorage.removeItem('isAuthenticated');
        // Redirect to the login page
        navigate('/');
    };

    return (
        <div id="kt_app_sidebar" className="app-sidebar flex-column" data-kt-drawer="true" data-kt-drawer-name="app-sidebar" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="225px" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle">
            {/* Sidebar Logo */}
            <div className="app-sidebar-logo px-6" id="kt_app_sidebar_logo">
                <Link to="/"> {/* Use Link instead of anchor tag */}
                    <img alt="Logo" src={logo} className="h-150px app-sidebar-logo-default" />
                    <img alt="Logo" src={minlogo} className="h-20px app-sidebar-logo-minimize" />
                </Link>
                <div id="kt_app_sidebar_toggle" className="app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate">
                    {/* SVG Icon for Sidebar Toggle can be added here */}
                </div>
            </div>

            {/* Sidebar Menu */}
            <div className="app-sidebar-menu overflow-hidden flex-column-fluid">
                <div id="kt_app_sidebar_menu_wrapper" className="app-sidebar-wrapper hover-scroll-overlay-y my-5">
                    <div className="menu menu-column menu-rounded menu-sub-indention px-3" data-kt-menu="true" data-kt-menu-expand="false">
                        {/* Apps Section */}
                        <div className="menu-item pt-5">
                            <div className="menu-content">
                                <span className="menu-heading fw-bold text-uppercase fs-7">Metamindful</span>
                            </div>
                        </div>

                        {/* Dashboard Link */}
                        <div className={`menu-item ${isActive('/home')}`}>
                            <Link className={`menu-link ${isActive('/home')}`} to="/home">
                              <span
                                  className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                <span className="svg-icon svg-icon-danger svg-icon-1">
                                   <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                                        <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                                        <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                                        <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                                   </svg>
                                </span>
                                </span>
                                <span className="menu-title">Dashboard</span>
                            </Link>
                        </div>

                        {/* Users Link */}
                        <div className={`menu-item ${isActive('/users')}`}>
                            <Link className={`menu-link ${isActive('/users')}`} to="/users">
                                 <span
                                     className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                <span className="svg-icon svg-icon-danger svg-icon-1">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z" fill="currentColor"></path>
                                        <path opacity="0.3" d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z" fill="currentColor"></path>
                                   </svg>
                                </span>
                                 </span>

                                <span className="menu-title">Users</span>
                            </Link>
                        </div>

                        {/* Send Notifications Link */}
                        <div className={`menu-item ${isActive('/send-notifications')}`}>
                            <Link className={`menu-link ${isActive('/send-notifications')}`} to="/send-notifications">
                                 <span
                                     className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                <span className="svg-icon svg-icon-danger svg-icon-1">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z" fill="currentColor"></path>
                                        <path opacity="0.3" d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z" fill="currentColor"></path>
                                   </svg>
                                </span>
                                 </span>

                                <span className="menu-title">Send Notifications</span>
                            </Link>
                        </div>

                        {/* Daily Remainder Link */}
                        <div className={`menu-item ${isActive('/daily-remainder')}`}>
                            <Link className={`menu-link ${isActive('/daily-remainder')}`} to="/daily-remainder">
                                 <span
                                     className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                <span className="svg-icon svg-icon-danger svg-icon-1">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z" fill="currentColor"></path>
                                        <path opacity="0.3" d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z" fill="currentColor"></path>
                                   </svg>
                                </span>
                                 </span>

                                <span className="menu-title">Daily Remainder</span>
                            </Link>
                        </div>

                        {/* Logout Link */}
                        <div className={`menu-item`}>
                            <a className="menu-link" onClick={handleLogout}>
                                 <span
                                     className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                <span className="svg-icon svg-icon-danger svg-icon-1">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z" fill="currentColor"></path>
                                        <path opacity="0.3" d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z" fill="currentColor"></path>
                                   </svg>
                                </span>
                                 </span>

                                <span className="menu-title">Logout</span>
                            </a>
                        </div>


                        {/* Add more menu items as needed */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
