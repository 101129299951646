import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, updateDoc, doc, deleteDoc, query, orderBy } from 'firebase/firestore';
import { db } from '../firebase';
import Select from 'react-select';
import AdminLayout from '../layouts/AdminLayout';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const DailyRemainder = () => {
    const [users, setUsers] = useState([]);
    const [remainderList, setRemainderList] = useState([]);
    const [loading, setLoading] = useState(true); // Loading state for fetching data
    const [saving, setSaving] = useState(false); // Saving state for button spinner
    const [editing, setEditing] = useState(false); // State to handle editing
    const [currentReminderId, setCurrentReminderId] = useState(null); // Track the current reminder being edited
    const [successMessage, setSuccessMessage] = useState(''); // Success message state

    const [showModal, setShowModal] = useState(false); // Manage modal visibility

    // Fetch users and reminders from Firebase on component load
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true); // Show loader while fetching data

                // Fetch users from Firestore
                const usersCollection = collection(db, 'users');
                const usersSnapshot = await getDocs(usersCollection);
                const usersList = usersSnapshot.docs.map(doc => ({
                    id: doc.id,
                    email: doc.data().email,
                    name: doc.data().name,
                }));
                setUsers(usersList);

                // Fetch reminders from Firestore, ordered by 'time' field
                const remindersCollection = collection(db, 'reminders');
                const q = query(remindersCollection, orderBy('time', 'desc')); // Order by 'time' in descending order
                const remindersSnapshot = await getDocs(q);
                const remindersList = remindersSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setRemainderList(remindersList); // Store reminders in state

                setLoading(false); // Hide loader once data is fetched
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false); // Hide loader if there's an error
            }
        };

        fetchData();
    }, []);

    // Dropdown options for selecting users
    const options = users.map(user => ({
        value: user.id, // Save user ID
        label: `${user.name} (${user.email})`,
        email: user.email, // Include email
    }));

    // Validation schema using Yup
    const validationSchema = Yup.object({
        title: Yup.string().required('Title is required'),
        message: Yup.string().required('Message is required'),
        time: Yup.date().min(new Date(), 'The scheduled time must be in the future').required('Time is required'),
        selectedUsers: Yup.array().min(1, 'Please select at least one user'),
    });

    // Formik form handling for create/update
    const formik = useFormik({
        initialValues: {
            title: '',
            message: '',
            time: '',
            selectedUsers: [],
        },
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                setSaving(true); // Show spinner on save button

                const reminderData = {
                    title: values.title,
                    message: values.message,
                    time:values.time, // Convert time to Date object

                    recipients: values.selectedUsers.map(user => ({
                        userId: user.value, // Store user ID
                        userEmail: user.email || user.label, // Store user email
                        status: 'Pending', // Default status for each user
                    })),
                };
                console.log(reminderData.recipients);

                console.log(values.selectedUsers);

                if (editing) {
                    // Update the existing reminder
                    const reminderRef = doc(db, 'reminders', currentReminderId);

                    await updateDoc(reminderRef, reminderData);


                    // await updateDoc(reminderRef, { title: values.title });
                    // await updateDoc(reminderRef, { message: values.message });
                    // await updateDoc(reminderRef, { time: new Date(values.time) }); // Ensure time is a Date object
                    //
                    // // Update recipients one by one (if necessary)
                    // for (let recipient of values.selectedUsers) {
                    //     await updateDoc(reminderRef, {
                    //         recipients: {
                    //             userId: recipient.value,
                    //             userEmail: recipient.email,
                    //             status: 'Pending'
                    //         }
                    //     });
                    // }

                    // Update local state
                    setRemainderList(prev =>
                        prev.map(item => (item.id === currentReminderId ? { ...item, ...reminderData } : item))
                    );

                    setSuccessMessage('Reminder updated successfully!');
                } else {
                    // Add new reminder to Firestore
                    const docRef = await addDoc(collection(db, 'reminders'), reminderData);
                    reminderData.id = docRef.id; // Add the new ID to the reminder data

                    // Update local state
                    setRemainderList(prev => [reminderData, ...prev]);

                    setSuccessMessage('Reminder saved successfully!');
                }

                // Hide success message after 3 seconds
                setTimeout(() => {
                    setSuccessMessage('');
                }, 3000);

                setSaving(false); // Hide spinner on success
                resetForm(); // Reset form after success
                setEditing(false); // Reset editing state
                setCurrentReminderId(null); // Reset current reminder ID

                // Close modal programmatically
                setShowModal(false); // Close modal after saving
            } catch (error) {
                console.error("Error saving reminder:", error);
                setSaving(false); // Hide spinner if there's an error
            }
        },
    });

    // Function to open modal for editing
    const handleEdit = (reminder) => {
        setEditing(true);
        setCurrentReminderId(reminder.id);

        // Convert the existing recipients to the format expected by react-select
        const selectedUsers = reminder.recipients.map(user => ({
            value: user.userId, // The ID of the user
            label: user.userEmail, // The email of the user
        }));

        // Set form values to the selected reminder
        formik.setValues({
            title: reminder.title,
            message: reminder.message,
            time: reminder.time,
            selectedUsers, // Set selected users in the correct format
        });

        // Open modal programmatically
        setShowModal(true);
    };

    // Function to delete a reminder
    const handleDelete = async (id) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this reminder?');
        if (!confirmDelete) return; // Exit if user cancels delete

        try {
            await deleteDoc(doc(db, 'reminders', id));
            setRemainderList(prev => prev.filter(reminder => reminder.id !== id));
            setSuccessMessage('Reminder deleted successfully!');
            setTimeout(() => {
                setSuccessMessage('');
            }, 3000);
        } catch (error) {
            console.error("Error deleting reminder:", error);
        }
    };

    // Define the columns for the DataTable, including Edit and Delete actions
    const columns = [
        {
            name: 'Title',
            selector: row => row.title,
            sortable: true,
        },
        {
            name: 'Message',
            selector: row => row.message,
        },
        {
            name: 'Scheduled Time',
            selector: row => new Date(row.time).toLocaleString(),
            sortable: true,
        },
        {
            name: 'Recipients',
            cell: row => (
                <ul>
                    {row.recipients.map((recipient, index) => (
                        <li key={index}>
                            {recipient.userEmail} - <strong>{recipient.status}</strong>
                        </li>
                    ))}
                </ul>
            ),
        },
        {
            name: 'Actions',
            cell: row => (
                <>
                    <button
                        className="btn btn-sm btn-warning me-2"
                        onClick={() => handleEdit(row)}
                    >
                        Edit
                    </button>
                    <button
                        className="btn btn-sm btn-danger"
                        onClick={() => handleDelete(row.id)}
                    >
                        Delete
                    </button>
                </>
            ),
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold', // Apply fw-bold
                fontSize: '14px',
                color: '#6c757d', // Apply text-muted
                backgroundColor: '#f8f9fa', // Apply bg-light
                borderRadius: '5px',
            },
        },
    };

    return (
        <AdminLayout>
            <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                <div id="kt_app_toolbar_container" className="app-container container-fluid d-flex flex-stack">
                    <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Metamindful</h1>
                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                            <li className="breadcrumb-item text-muted">
                                <Link to="/home" className="text-muted text-hover-primary">Home</Link>
                            </li>
                            <li className="breadcrumb-item">
                                <span className="bullet bg-gray-400 w-5px h-2px"></span>
                            </li>
                            <li className="breadcrumb-item text-muted">Schedule Notification Reminder</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div id="kt_app_content" className="app-content flex-column-fluid">
                <div id="kt_app_content_container" className="app-container container-fluid">
                    <div className="card card-body">
                        <div className="container mt-4">
                            {/* Display Success Message */}
                            {successMessage && (
                                <div className="alert alert-success">
                                    {successMessage}
                                </div>
                            )}

                            {/* New Reminder Button to Trigger Modal */}
                            <div className="card-header border-0 pt-5">
                                <h3 className="card-title align-items-start flex-column">
                                    <span className="card-label fw-bold fs-3 mb-1">Scheduled Reminders</span>
                                </h3>
                                <div className="card-toolbar">
                                    <button
                                        className="btn btn-sm btn-light btn-active-primary"
                                        onClick={() => {
                                            formik.resetForm(); // Reset the form when opening for a new reminder
                                            setEditing(false); // Reset editing state
                                            setShowModal(true); // Show modal
                                        }}
                                    >
                                        New Reminder
                                    </button>
                                </div>
                            </div>

                            {/* Modal Structure */}
                            {showModal && (
                                <div className="modal show d-block" tabIndex="-1">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title">{editing ? 'Edit Reminder' : 'New Reminder'}</h5>
                                                <button type="button" className="btn-close" onClick={() => setShowModal(false)}></button>
                                            </div>
                                            <div className="modal-body">
                                                <form onSubmit={formik.handleSubmit}>
                                                    <div className="mb-4">
                                                        <input
                                                            type="text"
                                                            className={`form-control mb-2 ${formik.touched.title && formik.errors.title ? 'is-invalid' : ''}`}
                                                            placeholder="Notification Title"
                                                            value={formik.values.title}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            name="title"
                                                        />
                                                        {formik.touched.title && formik.errors.title ? (
                                                            <div className="invalid-feedback">{formik.errors.title}</div>
                                                        ) : null}

                                                        <textarea
                                                            className={`form-control mb-2 ${formik.touched.message && formik.errors.message ? 'is-invalid' : ''}`}
                                                            placeholder="Notification Message"
                                                            value={formik.values.message}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            name="message"
                                                        />
                                                        {formik.touched.message && formik.errors.message ? (
                                                            <div className="invalid-feedback">{formik.errors.message}</div>
                                                        ) : null}

                                                        <input
                                                            type="datetime-local"
                                                            className={`form-control mb-2 ${formik.touched.time && formik.errors.time ? 'is-invalid' : ''}`}
                                                            value={formik.values.time}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            name="time"
                                                        />
                                                        {formik.touched.time && formik.errors.time ? (
                                                            <div className="invalid-feedback">{formik.errors.time}</div>
                                                        ) : null}
                                                    </div>

                                                    <div className="mb-4">
                                                        <Select
                                                            options={options}
                                                            isMulti
                                                            value={formik.values.selectedUsers} // Set the value to formik's selected users
                                                            onChange={(selected) => formik.setFieldValue('selectedUsers', selected)}
                                                            placeholder="Select Users to Notify"
                                                            name="selectedUsers"
                                                        />
                                                        {formik.touched.selectedUsers && formik.errors.selectedUsers ? (
                                                            <div className="text-danger">{formik.errors.selectedUsers}</div>
                                                        ) : null}
                                                    </div>

                                                    <div className="text-end">
                                                        <button className="btn btn-primary" type="submit" disabled={saving}>
                                                            {saving ? (
                                                                <>
                                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                    {editing ? 'Updating...' : 'Saving...'}
                                                                </>
                                                            ) : editing ? 'Update Reminder' : 'Save Reminder'}
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* DataTable for Scheduled Reminders */}
                            <div className="mt-4">
                                <DataTable
                                    columns={columns}
                                    data={remainderList.length === 0 ? [] : remainderList}
                                    pagination
                                    progressPending={loading} // Show loader while fetching data
                                    progressComponent={<div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div>} // Custom loader
                                    noDataComponent={<div>No reminders found.</div>} // Display when no data is present
                                    customStyles={customStyles} // Apply custom styles to table headers
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    );
};

export default DailyRemainder;
