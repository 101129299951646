// Home.js
import React from 'react';
import AdminLayout from '../layouts/AdminLayout';
import useDashboardStats from '../hooks/useDashboardStats';
import {Link} from "react-router-dom"; // Import the custom hook for dashboard stats

const Home = () => {
    const {
        totalUsers,
        activeUsers,
        paidUsers,
        trialUsers,
        expiredSubscriptions,
        activeSubscriptions,
        dayStatusCounts,
        topUsersByLastDayCompleted,
        topRecentLogins,
        topOldestLogins,
    } = useDashboardStats();

    return (
        <AdminLayout>
            {/* Toolbar Section */}
            <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                <div id="kt_app_toolbar_container" className="app-container container-fluid d-flex flex-stack">
                    <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Metamindful</h1>
                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                            <li className="breadcrumb-item text-muted">
                                <Link to="/home" className="text-muted text-hover-primary">Home</Link>
                            </li>
                            <li className="breadcrumb-item">
                                <span className="bullet bg-gray-400 w-5px h-2px"></span>
                            </li>
                            <li className="breadcrumb-item text-muted">Dashboard</li>
                        </ul>
                    </div>
                </div>
            </div>

            {/* Content Section */}
            <div id="kt_app_content" className="app-content flex-column-fluid">
                <div id="kt_app_content_container" className="app-container container-fluid">
                    {/* Stat Cards Grid */}
                    <div className="row g-6 mb-6">
                        {/* Total Users Card */}
                        <div className="col-sm-6 col-xl-3">
                            <div className="card h-lg-100">
                                <div className="card-body d-flex justify-content-between align-items-start flex-column">

                                    <div className="d-flex flex-column my-7">
                                        <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">{totalUsers}</span>
                                        <div className="m-0">
                                            <span className="fw-semibold fs-6 text-gray-400">Total Users</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Active Users Card */}
                        <div className="col-sm-6 col-xl-3">
                            <div className="card h-lg-100">
                                <div className="card-body d-flex justify-content-between align-items-start flex-column">

                                    <div className="d-flex flex-column my-7">
                                        <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">{activeUsers}</span>
                                        <div className="m-0">
                                            <span className="fw-semibold fs-6 text-gray-400">Active Users</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xl-3">
                            <div className="card h-lg-100">
                                <div className="card-body d-flex justify-content-between align-items-start flex-column">

                                    <div className="d-flex flex-column my-7">
                                        <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">{paidUsers}</span>
                                        <div className="m-0">
                                            <span className="fw-semibold fs-6 text-gray-400">Paid Users</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xl-3">
                            <div className="card h-lg-100">
                                <div className="card-body d-flex justify-content-between align-items-start flex-column">

                                    <div className="d-flex flex-column my-7">
                                        <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">{trialUsers}</span>
                                        <div className="m-0">
                                            <span className="fw-semibold fs-6 text-gray-400">Trail Users</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Expired Subscriptions Card */}
                        <div className="col-sm-6 col-xl-3">
                            <div className="card h-lg-100">
                                <div className="card-body d-flex justify-content-between align-items-start flex-column">

                                    <div className="d-flex flex-column my-7">
                                        <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">{expiredSubscriptions}</span>
                                        <div className="m-0">
                                            <span className="fw-semibold fs-6 text-gray-400">Expired Subscriptions</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xl-3">
                            <div className="card h-lg-100">
                                <div className="card-body d-flex justify-content-between align-items-start flex-column">

                                    <div className="d-flex flex-column my-7">
                                        <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">{activeSubscriptions}</span>
                                        <div className="m-0">
                                            <span className="fw-semibold fs-6 text-gray-400">Active Subscriptions</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>

                    {/* Top 10 Recent Logins */}
                    <div className="row g-6 mb-6">
                        <div className="col-6">
                            <div className="card h-lg-100">
                                <div className="card-body d-flex justify-content-between align-items-start flex-column">
                                    <h3 className="fw-bold fs-4 text-gray-800">Top 10 Recent Logins</h3>
                                    <table className="table table-striped">
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Email</th>
                                            <th>Last Login Date</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {topRecentLogins.map((user, index) => (
                                            <tr key={user.email}>
                                                <td>{index + 1}</td>
                                                <td>{user.email}</td>
                                                <td><span className="badge badge-light fw-bold"> {new Date(user.lastLoginDate.seconds * 1000).toLocaleString()} </span></td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="card h-lg-100">
                                <div className="card-body d-flex justify-content-between align-items-start flex-column">
                                    <h3 className="fw-bold fs-4 text-gray-800">Top 10 Oldest Logins</h3>
                                    <table className="table table-striped">
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Email</th>
                                            <th>Last Login Date</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {topOldestLogins.map((user, index) => (
                                            <tr key={user.email}>
                                                <td>{index + 1}</td>
                                                <td>{user.email}</td>
                                                <td><span className="badge badge-light fw-bold"> {new Date(user.lastLoginDate.seconds * 1000).toLocaleString()} </span></td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="card h-lg-100">
                                <div className="card-body d-flex justify-content-between align-items-start flex-column">
                                    <h3 className="fw-bold fs-4 text-gray-800">Top 10 Users by Last Day Completed</h3>
                                    <table className="table table-striped">
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Email</th>
                                            <th>Day</th>
                                            <th>Last Day Completed Date</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {topUsersByLastDayCompleted.map((user, index) => (
                                            <tr key={user.email}>
                                                <td>{index + 1}</td>
                                                <td><span className="text-gray-800 text-hover-primary mb-1">{user.name}</span>{user.email}</td>
                                                <td><span className="badge badge-light-success fw-bold"> {user.day} </span></td>
                                                <td><span className="badge badge-light fw-bold"> {user.lastDayCompletedDate} </span></td>

                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row g-6 mb-6">
                        <div className="col-12">
                            <div className="card h-lg-100">
                                <div className="card-body d-flex justify-content-between align-items-start flex-column">
                                    <h3 className="fw-bold fs-4 text-gray-800">Users by Day and Status</h3>
                                    <table className="table table-striped">
                                        <thead>
                                        <tr>
                                            <th>Day</th>
                                            <th>Pending</th>
                                            <th>Not Pending</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {Object.entries(dayStatusCounts).map(([day, statusCounts]) => (
                                            <tr key={day}>
                                                <td>{day}</td>
                                                <td>{statusCounts.pending}</td>
                                                <td>{statusCounts.not_pending}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Top 10 Users by Last Day Completed */}
                </div>
            </div>
        </AdminLayout>
    );
};

export default Home;
