import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust the path as needed
import Select from 'react-select'; // React Select for multi-select dropdown
import AdminLayout from '../layouts/AdminLayout';
import { Link } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';

const SendNotifications = () => {
    const [users, setUsers] = useState([]); // State to store users data
    const [results, setResults] = useState([]); // State to store notification results
    const [loading, setLoading] = useState(false); // Loading state for sending notifications

    // Fetch users data from Firestore
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const usersCollection = collection(db, 'users');
                const usersSnapshot = await getDocs(usersCollection);
                const usersList = usersSnapshot.docs
                    .map(doc => ({
                        id: doc.id,
                        email: doc.data().email,
                        name: doc.data().name,
                        token: doc.data().FCMtoken // FCM token
                    }))
                    .filter(user => user.token); // Only include users with FCM tokens
                setUsers(usersList); // Set filtered users data
            } catch (error) {
                console.error("Error fetching users data:", error);
            }
        };

        fetchUsers();
    }, []);

    // Options for the dropdown
    const options = users.map(user => ({
        value: user.token,
        label: `${user.name} (${user.email})`
    }));

    // Formik for handling form submission and validation
    const formik = useFormik({
        initialValues: {
            title: '',
            message: '',
            selectedUsers: [],
        },
        validationSchema: Yup.object({
            title: Yup.string().required('Notification title is required'),
            message: Yup.string().required('Notification message is required'),
            selectedUsers: Yup.array().min(1, 'Please select at least one user').required('Please select users'),
        }),
        onSubmit: async (values) => {
            setLoading(true); // Set loading state to true
            setResults([]); // Reset results before sending new notifications

            for (const user of values.selectedUsers) {
                const payload = {
                    fcmToken: user.value, // Individual FCM token
                    title: values.title,  // Notification title
                    body: values.message  // Notification message
                };

                try {
                    const response = await fetch('http://localhost:3001/api/notification/send', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(payload),
                    });

                    const data = await response.json();
                    if (response.ok) {
                        console.log(`Notification sent to ${user.label}: Success`);
                        setResults(prev => [...prev, { email: user.label, status: 'Success', response: data }]);
                    } else {
                        console.error(`Failed to send notification to ${user.label}:`, data);
                        setResults(prev => [...prev, { email: user.label, status: 'Failed', response: data }]);
                    }
                } catch (error) {
                    console.error(`Error sending notification to ${user.label}:`, error);
                    setResults(prev => [...prev, { email: user.label, status: 'Error', response: error.message }]);
                }
            }

            setLoading(false); // Set loading state to false after completion
        },
    });

    return (
        <AdminLayout>
            <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                <div id="kt_app_toolbar_container" className="app-container container-fluid d-flex flex-stack">
                    <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Metamindful</h1>
                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                            <li className="breadcrumb-item text-muted">
                                <Link to="/home" className="text-muted text-hover-primary">Home</Link>
                            </li>
                            <li className="breadcrumb-item">
                                <span className="bullet bg-gray-400 w-5px h-2px"></span>
                            </li>
                            <li className="breadcrumb-item text-muted">Send Notifications</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div id="kt_app_content" className="app-content flex-column-fluid">
                <div id="kt_app_content_container" className="app-container container-fluid">
                    <div className="card card-body">
                        <div className="container mt-4">
                            {/* Form */}
                            <form onSubmit={formik.handleSubmit}>
                                <div className="mb-4">
                                    <input
                                        type="text"
                                        className={`form-control mb-2 ${formik.touched.title && formik.errors.title ? 'is-invalid' : ''}`}
                                        placeholder="Notification Title"
                                        value={formik.values.title}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        name="title"
                                    />
                                    {formik.touched.title && formik.errors.title ? (
                                        <div className="invalid-feedback">{formik.errors.title}</div>
                                    ) : null}

                                    <textarea
                                        className={`form-control ${formik.touched.message && formik.errors.message ? 'is-invalid' : ''}`}
                                        placeholder="Notification Message"
                                        value={formik.values.message}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        name="message"
                                    />
                                    {formik.touched.message && formik.errors.message ? (
                                        <div className="invalid-feedback">{formik.errors.message}</div>
                                    ) : null}
                                </div>

                                <div className="mb-4">
                                    <Select
                                        options={options}
                                        isMulti
                                        onChange={(selectedOptions) => formik.setFieldValue('selectedUsers', selectedOptions)}
                                        placeholder="Select Users to Notify"
                                        name="selectedUsers"
                                    />
                                    {formik.touched.selectedUsers && formik.errors.selectedUsers ? (
                                        <div className="text-danger">{formik.errors.selectedUsers}</div>
                                    ) : null}
                                </div>

                                <div style={{ textAlign: 'end' }}>
                                    <button
                                        type="submit"
                                        className="btn btn-primary mt-3"
                                        disabled={loading}
                                    >
                                        {loading ? 'Sending...' : 'Send Notification'}
                                    </button>
                                </div>
                            </form>

                            {/* Notification Results */}
                            <div className="mt-4">
                                <h4>Notification Results</h4>
                                <ul>
                                    {results.map((result, index) => (
                                        <li key={index}>
                                            {result.email} - {result.status}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    );
};

export default SendNotifications;
