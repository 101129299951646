import React from 'react';


import avatar from '../assets/logo.png';

const Header = () => {
    return (
        <div className="app-header">
            <div className="app-container container-fluid d-flex align-items-stretch justify-content-between" id="kt_app_header_container">
                <div className="d-flex align-items-center d-lg-none ms-n3 me-1 me-md-2" title="Show sidebar menu">
                    <div className="btn btn-icon btn-active-color-primary w-35px h-35px" id="kt_app_sidebar_mobile_toggle">
                        {/* SVG Icon */}icon
                    </div>
                </div>

                <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1" id="kt_app_header_wrapper">
                    <div className="app-header-menu app-header-mobile-drawer align-items-stretch">
                        {/* Menu items */}
                    </div>
                    <div className="app-navbar flex-shrink-0">
                        <div className="app-navbar-item ms-1 ms-md-3">
                            <div className="cursor-pointer symbol symbol-30px symbol-md-40px">
                                <img src={avatar} alt="user" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
