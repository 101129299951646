import React from 'react';

const Footer = () => {
    return (
        <div id="kt_app_footer" className="app-footer" style={{"position": "fixed","bottom": "0","width": "100%"}}>
            <div className="app-container container-fluid d-flex flex-column flex-md-row flex-center flex-md-stack py-3">
                <div className="text-dark order-2 order-md-1">
                    <span className="text-muted fw-semibold me-1">2024&copy;</span>
                    <a href="https://keenthemes.com" target="_blank" rel="noreferrer" className="text-gray-800 text-hover-primary">MetaMindful</a>
                </div>

            </div>
        </div>
    );
};

export default Footer;
