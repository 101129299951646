import React from 'react';
import Header from '../includes/Header';
import Sidebar from '../includes/Sidebar';
import Footer from '../includes/Footer';

import '../assets/plugins/global/plugins.bundle.css';
import '../assets/css/style.bundle.css';

const AdminLayout = ({ children }) => {
    return (
        <div id="kt_app_body" data-kt-app-layout="dark-sidebar" data-kt-app-header-fixed="true"
              data-kt-app-sidebar-enabled="true" data-kt-app-sidebar-fixed="true" data-kt-app-sidebar-hoverable="true"
              data-kt-app-sidebar-push-header="true" data-kt-app-sidebar-push-toolbar="true"
              data-kt-app-sidebar-push-footer="true" data-kt-app-toolbar-enabled="true" className="app-default">

        <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
            <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
                <Header />
                <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                    <Sidebar />
                    <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                        <div className="d-flex flex-column flex-column-fluid mb-5" >

                                    {children}

                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
};

export default AdminLayout;
